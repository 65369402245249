import { template as template_5758dce4b23c44bbbf625ef40a4ba888 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_5758dce4b23c44bbbf625ef40a4ba888(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
