import { template as template_0984472a1c764af988b29348e3ba2565 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0984472a1c764af988b29348e3ba2565(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
