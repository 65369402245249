import { template as template_01cd0786f9124314b1a037496dfd5de1 } from "@ember/template-compiler";
const FKText = template_01cd0786f9124314b1a037496dfd5de1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
